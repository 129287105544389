import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sortable-csv"
export default class extends Controller {
  static targets = ['csv', 'input', 'item', 'itemInput', 'list', 'template']

  get firstItem () {
    return this.itemTargets.shift()
  }

  get lastItem () {
    return this.itemTargets.pop()
  }

  get newItem () {
    const clone = this.template.cloneNode(true)
    clone.querySelector('input').value = this.inputTarget.value

    return clone
  }

  get separator () {
    return this.csvTarget.dataset.separator
  }

  get template() {
    return this.templateTarget.content.firstElementChild
  }

  add () {
    const current = this.inputTarget.value
    let valor = this.csvTarget.value.trim().split(this.separator)

    if (current.trim() === '') {
      return
    } else if (valor.indexOf(current) < 0) {
      valor.push(current)
      valor = valor.filter(x => !!x)
      this.csvTarget.value = valor.join(this.separator).trim()
      this.listTarget.append(this.newItem)
      this.inputTarget.value = ''
    } else {
      alert('Ya existe')
    }
  }

  itemsToCsv () {
    this.csvTarget.value = this.itemInputTargets.map(input => input.value).join(this.separator)
  }

  moveDown (event) {
    const currentItem = event.target.closest('li')

    if (currentItem !== this.lastItem) {
      currentItem.parentElement.insertBefore(currentItem.nextElementSibling, currentItem)
      this.itemsToCsv()
    }
  }

  moveUp (event) {
    const currentItem = event.target.closest('li')

    if (currentItem !== this.firstItem) {
      currentItem.parentElement.insertBefore(currentItem, currentItem.previousElementSibling)
      this.itemsToCsv()
    }
  }

  remove () {
    event.target.closest('li').remove()
    this.itemsToCsv()
  }

  reset () {
    this.listTarget.innerHTML = ''
    this.csvTarget.value = ''
  }
}

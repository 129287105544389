import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sticky-item"
export default class extends Controller {
  static values = { header: String, options: Object }
  /** use these options for ajust your observer, as:
   * .item{ data: { ..., 'sticky-item': { 'options-value': {'rootMargin': '-50px 0px 0px 0px'}.to_json } } }
   * for observer options like 'root', 'rootMargin' & 'threshold'
   * or use stiky header className as a this.headerValue for automatic calculate 'rootMargin'
   * */

  initialize () {
    const options = this.optionsValue
    const stickyClass = 'rnk-StickyItem-fixed'
    const parentElement = this.element.parentElement
    const stickyHeaderHeight = this.headerValue ? document.querySelector(this.headerValue).offsetHeight : 0
    parentElement.style.setProperty("--sticky-top", `${stickyHeaderHeight}px`)

    const blockRect = this.element.getBoundingClientRect();
    const sectionRect = parentElement.getBoundingClientRect();
    const sectionBottom = sectionRect.top + sectionRect.height;
    const topOffset = sectionBottom - blockRect.top + stickyHeaderHeight;

    const StickyHandler = (entries) => {
      const entry = entries[0]
      const isStickyTime = !entry.isIntersecting && entry.boundingClientRect.top < topOffset
      this.element.classList.toggle(stickyClass, isStickyTime)
    }

    topOffset && Object.assign(options, { rootMargin: `-${topOffset}px 0px 0px 0px`})
    const observer = new window.IntersectionObserver(StickyHandler, options)
    observer.observe(parentElement)
  }
}

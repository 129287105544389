import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="open-modal-dialog"
export default class extends Controller {
  static values = { modal: String }

  openPopup () {
    document.querySelector(this.modalValue).open()
  }

}

import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="trix-word-count"
export default class extends Controller {
  static targets = ['level', 'counter']
  static values = { min: Number, max: Number }

  initialize () {
    this.min = parseInt(this.minValue)
    this.max = parseInt(this.maxValue)

    const countThem = event => {
      const { editor } = event.target
      const string = editor.getDocument().toString()
      const wordCount = string.trim().split(' ').length

      this.counterTarget.textContent = `(Hay ${wordCount})`
      this.levelTarget.style.color = this.calcColor(wordCount)
      this.levelTarget.style.width = this.calcWidth(wordCount)
    }

    document.addEventListener('trix-initialize', countThem)
    document.addEventListener('trix-focus', countThem)
    document.addEventListener('trix-change', countThem)
  }

  calcColor (wordCount) {
    if (wordCount > this.max) return 'red'

    return wordCount < this.min ? 'orange' : 'green'
  }

  calcWidth (wordCount) {
    const ratio = 100 / this.max

    return `${(wordCount < this.max ? wordCount : this.max) * ratio}%`
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ver-mas"
export default class extends Controller {
  static targets = ['container', 'trigger']
  static values = { feed: String, page: Number }

  nextPage () {
    this.element.dataset.loading = ''
    fetch(this.nextURL)
      .then(response => response.text())
      .then(responseText => {
        this.triggerTarget.remove()
        this.containerTarget.insertAdjacentHTML('beforeend', responseText)
        this.pageValue = this.pageValue + 1
        delete this.element.dataset.loading
      })
  }

  get nextPageNumber () {
    return this.pageValue + 1
  }

  get nextURL () {
    const url = new URL(location)

    url.pathname = this.feedValue
    url.search = `?page=${this.nextPageNumber}`

    return url
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller=ver-arbol-de-categorias
export default class extends Controller {

  static targets = [ 'categoria', 'container', 'categoriasAsociadas' ]
  static values = { feed: String, categoria: String, categoriasAsociadas: Object }

  showCategoriesTree() {
    event.preventDefault()
    const element = this.categoriaTarget
    const category = element.value

    this.element.dataset.loading = ''
    fetch(this.getURL(category))
      .then(response => response.text())
      .then(responseText => {
        this.containerTarget.innerHTML = responseText
        delete this.element.dataset.loading
      })
  }

  getURL(category) {
    const url = new URL(location)
    const select = this.categoriasAsociadasTarget
    const options = select.children

    let categoriasSeleccionadas = []
    for (let j = 0; j < options.length; j++) {
      categoriasSeleccionadas.push(options[j].value)
    }

    url.pathname = this.feedValue
    url.search = `?categoria_id=${this.categoriaValue}&categoria_nombre=${category}&categorias_seleccionadas=${categoriasSeleccionadas}`

    return url
  }
}
import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    successMessage: String,
    email: String
  }

  sendEmail(event) {
    event.preventDefault();
    const url = event.currentTarget.getAttribute('href');

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: this.emailValue })
    })
    .then(response => response.json())
    .then(() => {
      this.element.innerHTML = `<div class='rnk-Flash-alert'>${this.successMessageValue}</div>`;
    })
    .catch(error => {
      console.error("Error:", error);
    });
  }
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ['tab', 'tabPanel']
  static values = { watchCookie: String }

  initialize () {
    if (this.watchCookieValue && document.cookie.includes(`${this.watchCookieValue}=`)) {
      const [_, cookie] = document.cookie.split('; ')?.find(item => item.includes(`${this.watchCookieValue}=`))?.split('=')
      const tab = this.tabTargets.find(tab => tab.id === `tab-${cookie}`)

      if (!tab) return

      this.selectTab(tab)
      this.showTabContent(cookie)
    }
  }

  tabClick (event) {
    const clickedTab = event.target.dataset.forId ? event.target : event.target.closest('[data-for-id]')
    this.selectTab(clickedTab)
    const tabId = clickedTab.dataset.forId
    this.showTabContent(tabId)

    this.element.dispatchEvent(new CustomEvent('tab-click', {
      detail: tabId,
      bubbles: true
    }))
  }

  selectTab (tabElement) {
    this.tabTargets.forEach(t => t.setAttribute('aria-selected', false))
    tabElement.setAttribute('aria-selected', true)
  }

  showTabContent (id) {
    this.tabPanelTargets.forEach(t => t.setAttribute('hidden', true))
    this.tabPanelTargets.find(t => t.id === id).removeAttribute('hidden')
  }
}

import { Turbo } from "@hotwired/turbo-rails"
import "../controllers"

Turbo.session.drive = false

document.addEventListener('turbo:click', (event) => {
  if (event.target.getAttribute('href').includes('#')) {
    Turbo.session.navigator.__proto__.url = event.target.getAttribute('href')
  }

  event.preventDefault()

  Turbo.session.adapter.progressBar.setValue(0)
  Turbo.session.adapter.progressBar.show()
})

document.addEventListener('turbo:frame-load', async (event) => {
  let url_with_anchor = Turbo.session.navigator.__proto__.url

  if (url_with_anchor !=null && url_with_anchor.includes('#')) {
    Turbo.session.navigator.__proto__.url = null
    window.location.href = url_with_anchor
  } else {
    event.preventDefault()
    window.scrollTo(0, 0)
  }
})

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'formulario', 'messagetraducir', 'botonenviar' ]
  static values = { url: String, paisesidiomawp: String}

  connect() {
    this.formularioTarget.addEventListener('submit', this.sendPostRequest.bind(this))
  }

  sendPostRequest(event) {
    event.preventDefault()

    const idiomasSeleccionados = this.formularioTarget.querySelectorAll(
      'input[name="idioma_pais"]:checked'
    )

    if (idiomasSeleccionados.length === 0) {
      alert( 'Debes seleccionar como mínimo un idioma' )
      return setTimeout(() => {
        this.botonenviarTarget.removeAttribute('disabled')
      }, 1000)
    }

    const lenguajeSeleccionadoString = Array.from(idiomasSeleccionados).map( checkboxIdioma => checkboxIdioma.value).join(',')

    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idioma_pais: lenguajeSeleccionadoString
      })
    })
    .then(response => response.json())
    .then(responseData => {
      this.messagetraducirTarget.classList.remove('hidden')
      this.messagetraducirTarget.textContent =
        `Se ha enviado este artículo a traducir a los siguientes idiomas: ${this.idiomasEnEspanyol(responseData)}`
    })
    .catch(error => console.log(error))
  }

  idiomasEnEspanyol(lenguajesEnIngles) {
    const paisesIdiomaWP = JSON.parse(this.paisesidiomawpValue)

    const resultado = Object.keys(paisesIdiomaWP).filter(key => {
      let value = paisesIdiomaWP[key];
      return lenguajesEnIngles.some(lang => value.includes(lang));
    });

    return resultado.join(', ');
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-succes-message"
export default class extends Controller {
  static values = { text: String, style: { type: String, default: 'rnk-Flash-success' } }
  static targets = ['replace']


  initialize() {
    this.element.addEventListener('ajax:success', event => {
      if (event.detail[0]['status'] === 'ok') {
        const flash = document.createElement('div')
        flash.classList.add(...this.styleValue.split(' '))
        flash.textContent = this.textValue
        if (this.hasReplaceTarget) {
          this.replaceTarget.parentElement.replaceChild(flash, this.replaceTarget)
        } else {
          this.element.insertAdjacentElement('afterend', flash)

          setTimeout(() => {
            flash.remove()
          }, 4000)
        }
      }
    })
  }

}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['column']

  sort(event) {
    event.preventDefault()

    const column = event.target.dataset.column
    const direction = event.target.dataset.direction
    const rows = Array.from(this.element.querySelectorAll('tbody tr'))

    rows.sort((a, b) => {
      const aVal = a.querySelector(`td[data-column="${column}"]`).textContent.toLowerCase()
      const bVal = b.querySelector(`td[data-column="${column}"]`).textContent.toLowerCase()

      const aValue = parseFloat(aVal)
      const bValue = parseFloat(bVal)

      if (isNaN(aValue) || isNaN(bValue)) {
        if (direction === 'asc') {
          return aVal.localeCompare(bVal)
        } else {
          return bVal.localeCompare(aVal)
        }
      } else {
        if (direction === 'asc') {
          return aValue - bValue
        } else {
          return bValue - aValue
        }
      }
    })
    event.target.dataset.direction = event.target.dataset.direction === 'desc' ? 'asc' : 'desc'

    this.element.querySelector('tbody').append(...rows)
  }
}
